<template>
  <label class="checkbox" :class="{ 'checkbox--checked': isChecked }">
    <input
      class="checkbox__input"
      type="checkbox"
      :name="name"
      :checked="isChecked"
      @change="updateInput"
    />

    <div class="checkbox__box">
      <font-awesome-icon :icon="icons.check" size="xs" />
    </div>

    <span v-if="label" class="checkbox__label">{{ label }}</span>
  </label>
</template>

<script>
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "Checkbox",

  model: {
    prop: "modelValue",
    event: "change"
  },

  props: {
    value: [String, Number],
    modelValue: { default: "" },
    trueValue: { default: true },
    falseValue: { default: false },
    name: String,
    label: String
  },

  data: () => ({
    icons: {
      check: faCheck
    }
  }),

  computed: {
    isChecked() {
      return this.modelValue instanceof Array
        ? this.modelValue.includes(this.value)
        : this.modelValue === this.trueValue;
    }
  },

  methods: {
    updateInput(event) {
      const isChecked = event.target.checked;

      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue];

        if (isChecked) newValue.push(this.value);
        else newValue.splice(newValue.indexOf(this.value), 1);

        this.$emit("change", newValue);
      } else this.$emit("change", isChecked ? this.trueValue : this.falseValue);
    }
  }
};
</script>

<style scoped>
.checkbox {
  display: inline-flex;
  cursor: pointer;
}

.checkbox__box {
  width: var(--lengthMd2);
  height: var(--lengthMd2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  color: transparent;
  border: 2px solid var(--grayColor3);
  border-radius: 4px;
  transition: background-color 300ms;
}

.checkbox__input {
  display: none;
}

.checkbox__label {
  margin-left: var(--lengthSm1);
}

.checkbox--checked .checkbox__box {
  color: white;
  background-color: var(--mainColor1);
  border-color: var(--mainColor1);
}
</style>
