<template>
  <li class="list-item">
    <span v-handle class="handle">
      <font-awesome-icon :icon="icons.handle" />
    </span>

    <slot></slot>
  </li>
</template>

<script>
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { ElementMixin, HandleDirective } from "vue-slicksort";

export default {
  mixins: [ElementMixin],

  directives: { handle: HandleDirective },

  data: () => ({
    icons: {
      handle: faGripVertical
    }
  })
};
</script>

<style scoped>
.list-item {
  display: flex;
  align-items: flex-start;
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
}

.list-item + .list-item {
  margin-top: var(--lengthMd1);
}

.handle {
  flex: 0 0 auto;
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--lengthMd1) var(--lengthSm2);
  color: var(--fontColor2);
}

.handle:hover {
  color: var(--fontColor1);
}

.item {
  flex-grow: 1;
}
</style>
